var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "error-page", attrs: { "fill-height": "", tag: "section" } },
    [
      _c(
        "v-row",
        { staticClass: "text-center", attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("h1", { staticClass: "title font-weight-black" }, [
                _vm._v("\n        404\n      ")
              ]),
              _c("div", { staticClass: "display-3 mb-5 mt-10" }, [
                _vm._v("\n        Page not found :(\n      ")
              ]),
              _c("v-btn", { attrs: { depressed: "", to: "/" } }, [
                _vm._v("\n        Get me out of here!\n      ")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }